import React, { Component } from "react"
import { Link } from "gatsby"
import handleViewport from "react-in-viewport"
import anime from "animejs/lib/anime.es.js"
import { Swiper, Keyboard } from "swiper/dist/js/swiper.esm.js"

import ButtonAnimated from "components/ButtonAnimated"
// styles
import styles from "./ourworkcta.module.scss"

class OurWork extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transition: null,
      width: null,
      swiper: null,
    }
  }

  // componentDidMount() {
  //   if (typeof window !== `undefined`) {
  //     window.addEventListener("resize", this.calculateSize)
  //   }

  //   const transition = anime.timeline({
  //     easing: "easeOutCubic",
  //     duration: 800,
  //     autoplay: false,
  //   })
  //   transition
  //     .add({
  //       targets: this.refs.component.querySelector(`.${styles.title}`),
  //       duration: 400,
  //       translateY: ["24px", "0px"],
  //       opacity: [0, 1],
  //       easing: "easeInOutCirc(1, .5)",
  //     })
  //     .add({
  //       targets: this.refs.component.querySelectorAll(`.${styles.line}`),
  //       duration: 600,
  //       width: ["0%", "100%"],
  //       // opacity: [0, 1],
  //       easing: "easeInOutCirc(1, .5)",
  //     })
  //     .add(
  //       {
  //         targets: this.refs.component.querySelectorAll(`.${styles.items} > a`),
  //         duration: 600,
  //         translateY: ["24px", "0px"],
  //         opacity: [0, 1],
  //         delay: anime.stagger(100),
  //         easing: "easeInOutCirc(1, .5)",
  //       },
  //       "-=400"
  //     )
  //   this.setState({
  //     transition,
  //   })
  //   let swiper = new Swiper(`.${styles.swiperContainer}`, {
  //     slidesPerView: "auto",
  //     freeMode: true,
  //     slidesPerColumn: 3,
  //     slidesPerColumnFill: 'row',
  //     slideClass: styles.swiperSlide,
  //     wrapperClass: styles.swiperWrapper,
  //   })
  //   setTimeout(() => {
  //     swiper.slideTo(0)
  //   }, 1000),
  //     this.setState({ swiper: swiper })
  //   // this.calculateSize()
  // }
  // calculateSize = () => {
  //   if (typeof window !== `undefined` && this.state.swipe !== null) {
  //     if (this.state.width < 1024) {
  //       let swiper = new Swiper(`.${styles.swiperContainer}`, {
  //         slidesPerView: "auto",
  //         freeMode: true,
  //         slideClass: styles.swiperSlide,
  //         wrapperClass: styles.swiperWrapper,
  //       })
  //     }
  //     this.setState({
  //       width: window.innerWidth,
  //     })
  //   }
  // }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (nextProps.inViewport && nextProps.enterCount === 1) {
  //     this.state.transition.play()
  //   } else if (!nextProps.inViewport && nextProps.enterCount < 1) {
  //     // if (this.state.transition && !this.state.transition.reversed) {
  //     //   this.state.transition.play()
  //     // }
  //   }
  // }
  render() {
    const { title, button, items } = this.props.details
    const { width } = this.state
    return (
      <div className={styles.container} ref="component">
        <div className="wrapper">
          <header className={styles.header}>
            <h1 className={styles.title}>{title}</h1>
            {button.link ? (
              <ButtonAnimated to={button.link} text={button.text} />
            ) : (
              ""
            )}
          </header>
          <div className={styles.swiperContainer}>
            <span className={styles.line} />
            <div className={styles.swiperWrapper}>
              {items.map((item, i) => (
                <div className={styles.swiperSlide} key={i}>
                  <Link
                    key={i}
                    to={`${item.link}/`}
                    className={styles.link}
                    //alt={item.image.alt_text}
                  >
                    {item.image ? (
                      <img
                        className={styles.image}
                        src={item.image.source_url}
                        alt={item.image.alt_text}
                      />
                    ) : (
                      ""
                    )}
                  </Link>
                </div>
              ))}
            </div>
            <span className={[styles.line, styles.lineBottom].join(" ")} />
          </div>
          {/* <div className={styles.swiperContainer}>
            <span className={styles.line} />
            <div className={styles.swiperWrapper}>
              {items.map((item, i) => (
                <div className={styles.swiperSlide} key={i}>
                  <Link
                    key={i}
                    to={`${item.link}/`}
                    className={styles.link}
                    //alt={item.image.alt_text}
                  >
                    {item.image ? (
                      <img
                        className={styles.image}
                        src={item.image.source_url}
                        alt={item.image.alt_text}
                      />
                    ) : (
                      ""
                    )}
                  </Link>
                </div>
              ))}
            </div>
            <span className={[styles.line, styles.lineBottom].join(" ")} />
          </div> */}
        </div>
      </div>
    )
  }
}
const OurWorkTransition = handleViewport(OurWork, {
  threshold: 0.5,
})
export default OurWorkTransition
