import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import SEO from "components/SEO"
import Instafeed from "components/Instagram"
import Meetup from "components/Meetup"
import Quotes from "components/Quotes"
import PageRelated from "components/PageRelated"
import ContentBlock from "components/ContentBlock"
import ContentImage from "components/ContentImage"
import OurWorkSlider from "components/OurWorkCTA"
import OurWorkLogos from "components/OurWorkLogos"
import PageHero from "components/PageHero"
import VideoHero from "components/VideoHero"
import Team from "components/TeamGrid"
import AcademySignup from "components/AcademySignup"
import ImageDouble from "components/ImageDouble"


//ImageDouble, OurWorkLogos, OurDiscplines naar CMS
const PageTemplate = props => {
  const {
    hero,
    blocks,
    seo,
    color,
  } = props.data.wordpressPage
  return (
    <Layout location={props.location} navigation>
      <SEO seo={seo} />
      {hero.type === 'video' ? <VideoHero details={hero} kleur={color} /> : <PageHero details={hero} kleur={color} /> }
      {blocks.map((item, i) => {
        switch (item.type) {
          case "content":
            return (
              <ContentBlock
                color={color}
                key={i}
                large
                title={item.block.title}
                content={item.block.content}
                link={item.block.link}
                tabs={item.block.tabs}
                //contactperson={item.block.contactperson}
              />
            )
          case "imageLeft":
          case "imageCenter":
          case "imageRight":
            return (
              <ContentImage
                key={i}
                image={item.block.image}
                justify="baseline"
                type={item.block.align}
                kleur={color}
              />
            )
          case "imageDouble":
            return <ImageDouble key={i} details={item.block} />
          case "logos":
            return <OurWorkLogos key={i} details={item.block} />
          // case "instagram":
          //   return <Instafeed key={i} details={item.block} />
          case "references":
            return <Quotes key={i} details={item.block} />
          case "related":
            return <PageRelated key={i} details={item.block} />
          case "team":
            return <Team key={i} details={item.block} />
          // case "related":
          // return <Meetup details={item.block} />
          case "cta":
            return <Meetup key={i} type="noBackground" details={item.block} />
          case "academy-signup":
            return <AcademySignup />

          default:
        }
      })}
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
         query PageByID($id: String!) {
           site {
             siteMetadata {
               title
               author
             }
           }
           wordpressPage(id: { eq: $id }) {
             slug
             template
             blocks {
               type
               block {
                 title
                 titleMultiple
                 link {
                   label
                   link
                 }
                 items {
                   author
                   button {
                     link
                     text
                   }
                   company
                   description
                   link
                   quote
                   title
                   image {
                     alt_text
                     source_url
                     localFile {
                       extension
                       childImageSharp {
                         fluid(
                           quality: 100
                           srcSetBreakpoints: [400, 1024, 1280, 1921]
                           maxWidth: 1921
                         ) {
                           ...GatsbyImageSharpFluid_withWebp
                         }
                       }
                     }
                   }
                 }
                 team {
                   emailaddress
                   lastName
                   phonenumber
                   firstName
                   image {
                     localFile {
                       extension
                       childImageSharp {
                         fluid(
                           quality: 100
                           srcSetBreakpoints: [400, 1024, 1280, 1921]
                           maxWidth: 1921
                         ) {
                           ...GatsbyImageSharpFluid_withWebp
                         }
                       }
                     }
                   }
                 }
                 content
                 tabs {
                   title
                   price
                   content
                   contactperson {
                     image {
                       src {
                         localFile {
                           childImageSharp {
                             fluid(
                               quality: 100
                               srcSetBreakpoints: [400, 1024, 1280, 1921]
                               maxWidth: 1921
                             ) {
                               ...GatsbyImageSharpFluid_withWebp
                             }
                           }
                         }
                       }
                     }
                     email
                     phone
                   }
                 }
                 contactperson {
                   image {
                     src {
                       localFile {
                         childImageSharp {
                           fluid(
                             quality: 100
                             srcSetBreakpoints: [400, 1024, 1280, 1921]
                             maxWidth: 1921
                           ) {
                             ...GatsbyImageSharpFluid_withWebp
                           }
                         }
                       }
                     }
                   }
                   email
                   phone
                 }
                 align
                 button {
                   link
                   text
                 }
                 buttons {
                   link
                   text
                 }
                 imageLeft {
                   title
                   src {
                     localFile {
                       childImageSharp {
                         fluid(
                           quality: 100
                           srcSetBreakpoints: [400, 1024, 1280, 1921]
                           maxWidth: 1921
                         ) {
                           ...GatsbyImageSharpFluid_withWebp
                         }
                       }
                     }
                   }
                 }
                 imageRight {
                   title
                   src {
                     localFile {
                       childImageSharp {
                         fluid(
                           quality: 100
                           srcSetBreakpoints: [400, 1024, 1280, 1921]
                           maxWidth: 1921
                         ) {
                           ...GatsbyImageSharpFluid_withWebp
                         }
                       }
                     }
                   }
                 }
                 image {
                   src {
                     localFile {
                       childImageSharp {
                         fluid(
                           quality: 100
                           srcSetBreakpoints: [400, 1024, 1280, 1921]
                           maxWidth: 1921
                         ) {
                           ...GatsbyImageSharpFluid_withWebp
                         }
                       }
                     }
                   }
                 }
               }
             }
             color
             id
             wordpress_id
             hero {
               image {
                 src {
                   localFile {
                     childImageSharp {
                       fluid(
                         quality: 100
                         srcSetBreakpoints: [400, 1024, 1280, 1921]
                         maxWidth: 1921
                       ) {
                         src
                         ...GatsbyImageSharpFluid_withWebp
                       }
                     }
                   }
                 }
                 title
               }
               subtitle
               title {
                 text
                 invert
               }
               type
               video {
                  mp4 {
                    localFile {
                      publicURL
                    }
                  }
                 webm {
                   localFile {
                      publicURL
                    }
                 }
               }
             }
             seo {
               title
               siteName
               description
               image {
                 src {
                   localFile {
                    childImageSharp {
                      fluid(maxWidth: 1280, maxHeight: 853, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                   }
                 }
               }
             }
           }
         }
       `
