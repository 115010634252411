import React, { Component } from "react"
import { Link } from "gatsby"
import ButtonAnimated from "components/ButtonAnimated"
import Image from "gatsby-image"
import handleViewport from "react-in-viewport"
import anime from "animejs/lib/anime.es.js"

// styles
import styles from "./page-related-item.module.scss"

class PageRelatedItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transition: null,
      transitionInit: null,
      internalLink: true,
    }
  }
  componentDidMount() {
    const transition = anime.timeline({
      easing: "easeOutCubic",
      duration: 400,
      autoplay: false,
    })

    transition
      .add({
        targets: this.refs.page.querySelector(`.${styles.arrow}`),
        translateX: ["44px", "0px"],
        opacity: [0, 1],
        duration: 400,
        easing: "easeInOutExpo",
      })
      .add(
        {
          targets: this.refs.page.querySelector(`.${styles.subtitle} > span`),
          translateY: ["50px", "0px"],
          duration: 800,
          easing: "easeInOutExpo",
        },
        "-=700"
      )
      .add(
        {
          targets: this.refs.page.querySelector(`.${styles.reveal}`),
          height: ["0%", "100%"],
          duration: 400,
          // delay: 200,
        },
        "-=200"
      )

    const transitionInit = anime.timeline({
      easing: "easeOutCubic",
      duration: 1700,
      autoplay: false,
    })

    transitionInit
      .add({
        targets: this.refs.page.querySelector(`.${styles.image}`),
        opacity: [0, 1],
        duration: 400,
      })
      .add(
        {
          targets: this.refs.page.querySelector(`.${styles.reveal}`),
          height: ["100%", "0%"],
          duration: 400,
          delay: 200,
        },
        "-=200"
      )
      .add({
        targets: this.refs.page.querySelector(`.${styles.title} > span`),
        translateY: ["172px", "0px"],
        duration: 1200,
        easing: "easeInOutExpo",
      })
      .add(
        {
          targets: this.refs.page.querySelector(`.${styles.background}`),
          filter: ["grayscale(1)", "grayscale(0)"],
          duration: 400,
        },
        "-=200"
      )
      .add(
        {
          targets: this.refs.page.querySelector(`.${styles.description}`),
          translateY: ["300px", "0px"],
          duration: 400,
        },
        "-=600"
      )
      .add(
        {
          targets: this.refs.page.querySelector(`.${styles.content} > .button`),
          opacity: [0, 1],
          translateY: ["24px", "0px"],
          duration: 400,
        },
        "-=100"
      )
    // .add(
    //   {
    //     targets: this.refs.page.querySelectorAll(
    //       `.${styles.title} span > span`
    //     ),
    //     translateY: ["172px", "0px"],
    //     duration: 1200,
    //     easing: "easeInOutExpo",
    //     delay: anime.stagger(100),
    //   },
    //   "-=500"
    // )

    this.setState({
      transition: transition,
      transitionInit: transitionInit,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.inViewport && nextProps.enterCount === 1) {
      if (!this.state.transitionInit.reversed) {
        this.state.transitionInit.play()
      }
    }
  }


  hoverIn = () => {
    if (this.state.transition.reversed) {
      this.state.transition.reverse()
      this.state.transition.play()
    } else {
      this.state.transition.play()
    }
  }
  hoverOut = () => {
    if (!this.state.transition.reversed) {
      this.state.transition.reverse()
      this.state.transition.play()
    }
  }
  render() {
    const { title, description, button, image } = this.props.content
    // console.log("Podcastitem image", this.props.content)
    return (
      button.link && button.link.includes('https') ? (
        <a
        href={button.link}
        className={styles.item}
        onMouseEnter={this.hoverIn}
        onMouseLeave={this.hoverOut}
        ref="page"
      >
        <header className={styles.header}>
          <div className={styles.image}>
            <span className={styles.reveal} />
            {image && (
              <Image
                fluid={image.localFile.childImageSharp.fluid}
                className={styles.background}
                alt={image.alt_text}
              />
            )}
          </div>
          <h4 className={styles.title}>
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </h4>
        </header>
        <div className={styles.content}>
          <p className={styles.description}>{description}</p>
          <ButtonAnimated type="dummy" text={button.text} />
        </div>
      </a>
      ) : (
        <Link
        to={`${button.link}/`}
        className={styles.item}
        onMouseEnter={this.hoverIn}
        onMouseLeave={this.hoverOut}
        ref="page"
      >
      <header className={styles.header}>
          <div className={styles.image}>
            <span className={styles.reveal} />
            {image && (
              <Image
                fluid={image.localFile.childImageSharp.fluid}
                className={styles.background}
                alt={image.alt_text}
              />
            )}
          </div>
          <h4 className={styles.title}>
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </h4>
        </header>
        <div className={styles.content}>
          <p className={styles.description}>{description}</p>
          <ButtonAnimated type="dummy" text={button.text} />
        </div>
      </Link>
      )

    )
  }
}

const PageRelatedItemTransition = handleViewport(PageRelatedItem, {
  threshold: 0.4,
})
export default PageRelatedItemTransition
