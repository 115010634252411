import React, { Component } from "react"
import { Link } from "gatsby"
import ButtonAnimated from "components/ButtonAnimated"

// styles
import styles from "./ourworklogos.module.scss"

class OurWorkLogo extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  render() {
    const { title, button, items } = this.props.details
    return (
      <div className={styles.container} ref="component">
        <div className="wrapper">
          <header className={styles.header}>
            <h1 className={styles.title}>{title}</h1>
            {button.link ? (
              <ButtonAnimated to={button.link} text={button.text} />
            ) : (
              ""
            )}
          </header>
          <span className={styles.line} />
          <div className={styles.logoGrid}>
          {items.map((item, i) => (
            <div className={styles.logo} key={i}>
              <Link
                key={i}
                to={`${item.link}/`}
                className={styles.link}
                //alt={item.image.alt_text}
              >
                {item.image ? (
                  <img
                    className={styles.image}
                    src={item.image.source_url}
                    alt={item.image.alt_text}
                  />
                ) : (
                  ""
                )}
              </Link>
            </div>
          ))}
          </div>
          <span className={styles.line} />
        </div>
      </div>
    )
  }
}

export default OurWorkLogo
