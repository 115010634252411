import React, { Component } from "react"

import TeamItem from "components/TeamItem"
import stylesItem from "../TeamItem/teamItem.module.scss"
import styles from "./teamGrid.module.scss"
import { Swiper } from "swiper/dist/js/swiper.esm.js"

class TeamGrid extends Component {
  componentDidMount = () => {
    let swiper = new Swiper(`.${styles.swiper}`, {
      slidesPerView: "auto",
      spaceBetween: 30,
      direction: "horizontal",
      slideClass: stylesItem.personContainer,
      speed: 200,
    })
  }

  render() {
    const { team, title } = this.props.details
    const { type } = this.props
    //console.log("items", team)

    return (
      <div
        className={[styles.team, type ? styles[`${type}`] : null].join(" ")}
        ref="component"
      >
        <div className="wrapper">
          <h3
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div className={styles.TeamGrid}>
            <div className={styles.swiper}>
              <div className={[styles.wrapper, "swiper-wrapper"].join(" ")}>
                {team.map((item, i) => (
                  <TeamItem key={i} details={item} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TeamGrid
