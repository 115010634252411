import React, { Component } from "react"
import PageRelatedItem from "components/PageRelatedItem"

// styles
import styles from "./page-related.module.scss"

class PageRelated extends Component {
  render() {
    const { items } = this.props.details
    //console.log("items", items)
    return (
      <div className={["wrapper"]} ref="component">
        <div className={styles.container}>
          {items.map((item, i) => (
            <PageRelatedItem content={item} key={i} />
          ))}
        </div>
      </div>
    )
  }
}

export default PageRelated
