import React, { Component } from "react"
import Image from "gatsby-image"

// import ImageGridItem from "components/ImageGridItem"

import styles from "./image-double.module.scss"

class ImageDouble extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }

  componentDidMount(){
    
  }

  render() {
    const { details } = this.props;
    return (
      <div className={[styles.imageContainer].join(" ")}>
        <Image
          className={styles.image}
          title={details.imageLeft.title}
          fluid={details.imageLeft.src.localFile.childImageSharp.fluid}
        />
        <Image
          className={styles.image}
          title={details.imageRight.title}
          fluid={details.imageRight.src.localFile.childImageSharp.fluid}
        />
      </div>
    )
  }
}

export default ImageDouble
