import React, { Component } from "react"
import Helmet from "react-helmet"
import Image from "gatsby-image"

//styles
import styles from "./teamItem.module.scss"

// TODO: Add correct info to JsonLD
class TeamItem extends Component {
  render() {
    const {
      emailaddress,
      firstName,
      phonenumber,
      lastName,
      image,
    } = this.props.details
    const { type } = this.props
    let featuredImage = false

    // console.log("hello", image)

    if (image !== null && image.localFile) {
      featuredImage = image.localFile.childImageSharp.fluid
    }
    // TODO: Juiste job titel in Json plaatsen, optioneel nog Social media profile + Image
    return (
      <>
        {/* <Helmet>
          <script type="application/ld+json">{`
            {
              "@context": "https://schema.org/",
              "@type": "Person",
              "name": "${firstname} ${lastname}",
              "url": "https://keytoe.nl/team",
              "image": "https://via.placeholder.com/280x280",
              "sameAs": [
                "https://linkedin.com/",
                "https://instagram.com/"
              ],
              "jobTitle": "All round Developer",
              "worksFor": {
                "@type": "Organization",
                "name": "Keytoe"
              }  
            }
        `}</script>
        </Helmet> */}
        <div
          className={[
            styles.personContainer,
            type ? styles[`${type}`] : null,
          ].join(" ")}
          ref="component"
        >
          {featuredImage && (
            <Image
              fluid={featuredImage}
              className={styles.image}
              alt={image.alt_text}
            />
          )}
          <h3>{firstName}</h3>
          <span>{emailaddress}</span>
          <span>{phonenumber}</span>
        </div>
      </>
    )
  }
}

export default TeamItem
