import React, { Component } from "react"
import anime from "animejs/lib/anime.es.js"
import Image from "gatsby-image"

import styles from "./video-hero.module.scss"

import Trailer from 'assets/video/576.mp4';
import TrailerWebM from 'assets/video/576.webm';

class VideoHero extends Component {
  constructor (props) {
    super(props);
  }
  componentDidMount() {
    //console.log('Color1', this.props.kleur)
    const tl = anime
      .timeline({
        easing: "easeOutCubic",
        duration: 1500,
      })
      .add(
        {
          targets: this.refs.hero.querySelector(`.${styles.image}`),
          opacity: [0, 1],
          duration: 400,
        },
        100
      )
      .add({
        targets: this.refs.hero.querySelector(`.${styles.background}`),
        height: ["0%", "100%"],
        duration: 400,
      })
      // .add(
      //   {
      //     targets: this.refs.hero.querySelector(`.${styles.background}`),
      //     filter: ["grayscale(1)", "grayscale(1)"],
      //     duration: 400,
      //   },
      //   "-=200"
      // )
      // .add(
      //   {
      //     targets: this.refs.hero.querySelector(`.${styles.reveal}`),
      //     height: ["0%", "100%"],
      //     duration: 400,
      //     delay: 200,
      //   },
      //   "-=200"
      // )
      .add(
        {
          targets: this.refs.hero.querySelectorAll(
            `.${styles.title} span > span`
          ),
          translateY: ["172px", "0px"],
          duration: 1200,
          easing: "easeInOutExpo",
          delay: anime.stagger(100),
        },
        this.props.details.image.src !== null ? "-=500" : 0
      )
      
      .add(
        {
          targets: this.refs.hero.querySelectorAll(
            `.${styles.subtitle} span:first-child`
          ),
          translateY: ["104px", "0px"],
          duration: 800,
          easing: "easeInOutExpo",
          delay: anime.stagger(100),
        },
        this.props.details.image.src !== null ? "-=600" : 200
      )
  }

  render() {
        const makeMuted = (elt) => {
      if (elt) {
        elt.muted = true;
      }
    };
      const { title, subtitle, image, video } = this.props.details
      const { type } = this.props
      const color = this.props.kleur
    let featuredImage = false

    if (image.src !== null && image.src.localFile) {
      featuredImage = image.src.localFile.childImageSharp.fluid.src
    }
    return (
      <section className={styles.hero} ref='hero'>
        <div className={[styles.wrapper, 'wrapper'].join(' ')}>
          <div className={styles.image}>
            {/* <span className={styles.reveal} style={{ backgroundColor: color }} /> */}
            <video
              className={styles.background}
              poster={featuredImage}
              muted='muted'
              playsInline='playsinline'
              autoPlay='autoplay'
              loop='loop'
              ref={makeMuted}
            >
              <source src={video.mp4.localFile.publicURL} type='video/mp4'></source>
              <source src={video.webm.localFile.publicURL} type='video/webm'></source>
            </video>
          </div>
          <header className={styles.header}>
            <h1 className={styles.title}>
              {title.map((row, i) => (
                <span
                  key={i}
                  className={row.invert === true ? styles.invert : null}
                >
                  <span>{row.text}</span>
                </span>
              ))}
            </h1>
            {subtitle && (
            <span className={styles.subtitle}>
              <span>{subtitle}</span>
              {/* <span className={styles.opacity}>
                €16,67/maand (Jaarlijks gefactureerd)
              </span> */}
            </span>
           )}
          </header>
        </div>
      </section>
    )
  }
}

export default VideoHero
